.blog{
    background: #ffffff; 
    padding-top: 50px;
    padding-bottom: 140px;
}
.blog-content{
    color: #000;
}

.blog-content p , li {
    color: #000;
    font-size: 20px;
}



.blog-content  h3 {
    color: #000;
    padding: 20px 0px 10px 0px; 
  
}
