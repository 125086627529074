.progress {
    
    width: 300px;
    height: 10px;
    background: linear-gradient(#4dff47 0 0) left/0% 100% no-repeat
        #1c1c1c;
    animation: progress-jgv380 1s infinite linear;
 }
 
 @keyframes progress-jgv380 {
    100% {
       background-size: 100% 100%;
    }
 }