.product-carousel .thumbs .thumb{
    height: 40px !important;
}

.product-carousel .slide img{
    height: 286px;
}

.product-details{
    height: calc(100vh - 180px)
}

@media (max-width: 768px){
    .product-details{
        height: auto;
    }
}

.attention-link {
    padding: 15px 30px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    animation: shake 3s infinite;
    transition: background-color 0.2s;
    color: #4CAF50;
    font-weight: 700;
}

.attention-btn:hover {
    background-color: #333;
    color: #fff;
}

@keyframes shake {
    0%, 100% {
        transform: translateY(0);
    }
    25%, 75% {
        transform: translateY(-5px);
    }
    50% {
        transform: translateY(5px);
    }
}
