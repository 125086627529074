.navbar .navbar-nav .nav-link {
    color: white;
    padding: 1rem;
    text-align: center;
    width: 120px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.get-in-touch-btn {
    background-color: #3feb3f;
    
}

.navbar .navbar-nav .nav-link:hover {
    background-color: #3feb3f;
    color: white !important;
}

.navbar .navbar-brand {
    padding: 0 0.6em;
}

.navbar .navbar-nav .nav-link {
    padding: 1em 0.7em;
    transition: all 0.5s;
    font-weight: 600;
}

.header-contact-info{
    font-weight: 600 !important;
    color: black !important;
}
.header-contant-bar{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 2.5rem;
    background: linear-gradient(to right top,#49c628 10%,#3feb3f);
    padding: 25px;
}

.about-us-column, .contact-us-column, .news-letter{
    width: 33.333333%;
} 

.mobile-img-small-footer{
    display: none;
}
.desktop-img-small-footer{
    width: 150px;

}

.small-footer-container{
    padding: 0px 150px;
}
@media (max-width: 1024px) {
    .small-footer-container{
        padding: 0px 150px;
    }

    .mobile-img-small-footer{
        display: block;
        width: 50%;
        height: 60%;
    }
}
@media (max-width: 768px) {
    .header-content{
        display: none !important;
    }
    .small-footer-container{
        padding: 0px 50px;
    }

    .about-us-column, .contact-us-column, .news-letter{
        width: 100%;
    }
    .get-in-touch-btn{
        margin-left: 0 !important;
    }
    .nav-container{
        padding: 1rem;
    }
}
@media (min-width: 900px) and (max-width: 1200px) {
    
    .small-footer-container{
        padding: 0px 10px;
    }
    
  }