.landing-slider-overlay {
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 190px;
}

.landing-card {
    background: linear-gradient(0deg, rgba(17, 17, 17, .815), transparent);
    display: flex;
    position: relative;
    width: 400px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    z-index: 10;
    background: linear-gradient(to right top, #49c628 10%, #3feb3f);
    overflow: hidden;
    position: absolute;
    transition: all 0.5s ease-in-out;
    animation: popIn2 1000ms;
    top: 120px;
    left: 100px;
    bottom: 0;
}

@keyframes popIn2 {
    from { top: -5rem; }
    to { top: 120px; }
}


.landing-card-inner {
    position: relative;
    margin: 30px 30px 50px 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.landing-card-inner h1 {
    font-size: 4rem;
    font-family: Teko, sans-serif;
    color: #fff;
    line-height: 3rem;
    margin: 0;
    text-align: start;
    letter-spacing: 5px;
}

.landing-card-inner p {
    font-size: 1.2rem;
    color: #fff;
}

.landing-card-btn {
    cursor: pointer;
    margin-top: 2rem;
    background: white;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 25px;
    box-shadow: -15px 10px 0 rgba(0, 0, 0, .1);
    font-size: 1.5rem;
    color: #49c628;
    font-weight: bold;
}

.landing-card-btn:hover {
    transition: .1s ease-in;
    box-shadow: 15px 10px 0 rgba(0, 0, 0, .1);
}

.carousel-container {
    height: calc(100vh - 120px);
}

.landing-card-md {
    display: none;
}

.landing-slider {
    position: absolute;
    width: 100%;
}
.landing-page-md{
    display: none;
}

@media (max-width: 1024px) {
    .landing-page-lg{
        display: none !important;
    }
    .landing-page-md{
        display: flex;
    }
    .landing-slider-overlay {
        display: none !important;
    }

    .landing-card-md {
        display: block;
    }

    .landing-card-md h1 {
        font-family: Teko, sans-serif;
        color: #fff;
        line-height: 3.5rem;
        margin: 0;
        text-align: start;
        letter-spacing: 5px;
    }

    .carousel-container {
        height: auto !important;
    }

    .landing-card-md .card {
        background: linear-gradient(to right top, #49c628 10%, #3feb3f);
        color: white;
        padding: 40px 10px;
    }

    .landing-carousel-img {
        height: 512px;
    }
    .landing-slider-md img{
        height: 546px;
    }
    .carousel-control-prev{
        left: 0 !important;
      }
      .carousel-control-next{
        right: 0 !important;
      }
}

@media (max-width: 992px) {}

@media (max-width: 768px) {
    .landing-card {
        display: none !important;
    }

    .landing-slider {
        z-index: -1 !important;
        position: unset;
        width: 100%;
    }

    .landing-slider-md img{
        height: 346px;
    }
}

@media (max-width: 576px) {
    .landing-card-md .card {
        background: linear-gradient(to right top, #49c628 10%, #3feb3f);
        color: white;
        padding: 40px 10px;
    }
    .landing-card-md h1{
        font-size: 3rem !important;
        line-height: 2.5rem !important;
    }

    .landing-slider-md img {
        height: 280px;
    }

    .carousel-container {
        height: auto !important;
    }
}