// @import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500;600;700&display=swap");

$body-bg: #000;
$theme-colors: (
  'primary': rgba(74, 199, 40, 1.0),
  'danger': rgb(173, 10, 10),
  'success': green
);

// Import Bootstrap and its default variables
@import "bootstrap/scss/bootstrap.scss";


@each $breakpoint,
$container-max-width in $container-max-widths {
  .container-#{$breakpoint} {
    @extend .container-fluid;
  }
  
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    %responsive-container-#{$breakpoint} {
      max-width: $container-max-width;
    }
  }
}


body{
  color: white;
}
