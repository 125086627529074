@import url(./styles/technology.css);
@import url(./styles/layout.css);
@import url(./styles/ourproducts.css);
@import url(./styles/landingcarousel.css);
@import url(./styles/blog.css);
@import url(./styles/loader.css);
body{
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  -webkit-font-smoothing: antialiased;
}

.content-container{
  height: calc(100vh - 545.67px);
}
.services-icons {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  font-size: 3rem;
  color: #03c03c;
}

.body{
  position: relative;
}

.main-container{
  margin-top: 120px;
  position: relative;
}

.carousel-control-prev{
  width: 5% !important;
}
.carousel-control-next{
  width: 5% !important;
}
.our-mission{
  position: absolute;
  bottom: 30px;
  left: -65px;
}
.carousel-container .slick-dots{
  bottom: 15px !important;
}


.landing-card::after, .our-mission::after, .landing-card-md::after{
  position: absolute;
  content: "";
  background-image: url(./assets/images/hero_arrow.png);
  background-repeat: no-repeat !important;
  background-size: 100% !important;
  left: 0;
  right: 5px;
  bottom: 0px;
  height: 28px;
  animation: slide1 1s ease-in-out infinite;
  margin-left: 0px;
}
.carousel-container .slick-prev{
  z-index: 9;
  left: 15px !important;
  height: 40px;
  width: 40px;
}
.carousel-container .slick-next{
  z-index: 9;
  right: 15px !important;
  height: 40px;
  width: 40px;
}

.gallery-img img{
 cursor: pointer;
 transition: all .2s!important;
 /* transform: scale(1.5) !important; */
}

.gallery-img .product-name{
  position: absolute;
  height: 170px;
  width: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  top: 0;
  background: black;
  --webkit-animation: fadein2 400ms;
    animation: fadein2 400ms;
    cursor: pointer;
}
.gallery-img:hover .product-name{
  display: flex;
}

@keyframes fadein2 {
  from { opacity: 0; }
  to { opacity: 0.8; }
}

.readable-font{
  font-size: 1.2rem;
  color: white !important;
}
.whatsapp-ico{
  position: fixed;
  bottom: 40px;
  right: 30px;
  cursor: pointer;
}

.whatsapp-ico img{
  height: 60px;
}

.about-us-text{
  font-family: Roboto, sans-serif;
  line-height: 2rem;
  text-align: start; 
  font-size: 1.2rem;
  color: #dfd9d9 !important;
}

.social-media-icons{
  justify-content: flex-end;
}
@keyframes slide1 {
  0%,
  100% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(5px, 0);
    -webkit-transform: translate(5px, 0);
    -moz-transform: translate(5px, 0);
    -ms-transform: translate(5px, 0);
    -o-transform: translate(5px, 0);
  }
}

.custom-input{
  border: 2px solid rgb(35, 35, 35);
  background: rgb(35, 35, 35);
  color: white !important;
  transition: border-bottom 0.1s ease-in-out;

}
.custom-input:focus {
  border-bottom: 2px solid rgb(43, 255, 0) !important;
}
.custom-input:focus {
  outline: none;
  
}


.banner-nav {
  cursor: pointer;
  margin-top: 2rem;
  background: white;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 25px;
  color: #3feb3f;
  box-shadow: -15px 10px 0px rgba(0, 0, 0, 0.1);
  /* width: 50%; */
  font-size: 1.4rem;
  font-weight: bold;
}
.banner-nav:active{
  background: white !important;
  color: #03c03c !important;
}
.banner-nav:hover {
  transition: ease-in 100ms;
  color: #08af3a;
  background: white;
  box-shadow: 15px 10px 0px rgba(0, 0, 0, 0.1);
}

.about-nav-btn {
  cursor: pointer;
  margin-top: 3rem;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 25px;
  color: #3feb3f;
  background: white;
  box-shadow: -10px 10px 0px #3feb3f;
  font-weight: bold;
}

.about-nav-btn:hover {
  color: #3feb3f;
  background: white;
  transition: ease-in 100ms;
  box-shadow: 15px 10px 0px #3feb3f;
}
.careers{
  position: relative
}
.careers::after {
  position: absolute;
  content: "";
  background-image: url(./assets/images/footer_arrow.png);

  left: -5px;
  right: 0;
  height: 59px;
  bottom: -26px;
  background-repeat: no-repeat;
  
  animation: slide1 1s infinite;
  margin-left: 0px;
  width: 100%; 
}

.slick-dots li.slick-active button::before,.slick-dots li button::before{
  color: #fff !important;
}
.slick-dots li button:before{
  font-size: 15px;
}

.industry-card{
  /* height: 300px;  */
  background: #121212;
  width: 33.3333333%;
  cursor: pointer;
}

.tech-card:hover{
  cursor: pointer;
}

.industry-card:hover{
  /* height: 300px;  */
  background: #1c1c1c;
}


.navbar-brand img{
  width: 300px;
}


.landing-carousel-img{
  height: calc(100vh - 110px);
}

@media only screen and (min-width: 1024px) {
  .navbar {
    padding: 0;
  }
  .navbar .navbar-nav .nav-link {
    padding: 1em 0.7em;
  }
  .navbar .navbar-brand {
    padding: 0 0.8em;
  }
}
@media only screen and (max-width: 1024px) {
  .nav-link{
    width: auto !important;
    height: auto !important;
  }
  .industry-card{
    width: 100% !important;
    padding: 0 !important;
  }
}

@media (max-width: 1024px){
  .main-container{
    margin-top: 70px;
  }
  .technology-title{
    font-size: 20px;
  }
  .technology-description{
    font-size: 12px;
  }
  .our-mission{
    display: none !important;
  }
  .industries{
    margin: 0 !important;
  }
  .landing-card-md::after{
    height: 45px !important;
  }
}

@media (max-width: 768px) {

  .main-container{
    margin-top: 40px;
  }
  .technology{
    flex-wrap: wrap !important;
  }
  .feature img{
    width: 100% !important;
    height: auto !important;
  }
  .social-media-icons{
    justify-content: center !important;
  }
  
  .whatsapp-ico img{
    height: 60px !important;
  }
}
@media (max-width: 576px){
  .readable-font{
    font-size: 1rem !important;
  }
  .w-md-100{
    width: 100% !important;
  }
  .landing-card-md::after{
    height: 24px !important;
  }
}
@media (max-width: 425px) {
  .navbar-brand img{
    width: 235px;
  }
  .main-container{
    margin-top: 60px;
  }
  .prod-desc h5{
    font-size: 12px !important; 
    font-weight: 400 !important;
  }
  .slick-next, .slick-prev{
    display: none !important;
  }
}

@media (max-width: 320px) {
  .main-container{
    margin-top: 234px;
  }
}