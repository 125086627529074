.newLaunch{
    position: relative;   
    background: #CF0808;
    background: linear-gradient(to right, #CF0808 22%, #F5660D 100%);
    box-shadow: 0px 0px 20px 1px rgba(255, 34, 34, 0.6);
    animation: heartbeatAnimation 2s initial;
    animation-delay: 5s;
}


.logo{
  width: 220px !important;
  height: 40px;

}
.mobile-logo{
  width: 200px !important;
  height: 30px;
}

.tag{
    position: absolute;
    top:0px;
    right: 0px;
    border-radius: 10px 0px 10px 10px;
    padding: 1px 6px;
    background: rgb(255, 255, 255);
    color: #000;
    box-shadow: 0px 0px 10px 0px rgba(255,255,255,0.6);
    display: inline-block;
    animation: heartbeatAnimation 1s infinite;
    animation-delay: 2s;
}


@keyframes heartbeatAnimation {
    0%, 100% {
        transform: scale(1);
      }
      20%, 80% {
        transform: scale(1.05);
      }
      40%, 60% {
        transform: scale(1);
      }
  }
  
.newLaunchActive{
    position: relative;   
    background: #F5660D;
    animation: heartbeatAnimation 2s initial;
    animation-delay: 5s;

}

.newLaunchActive:hover{
    position: relative;   
    background: #F5660D;
    animation: heartbeatAnimation 2s initial;
    animation-delay: 5s;

}
.newLaunchTextActive{
color:#fff;   
}

.navBrand{
  flex: 1;
}

.navContainer{
  display: flex;
  align-items: center;
  justify-content: center;
}