.TDiconButton {
    font-size: 50px;
    padding: 10px;
    border: 4px dashed #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    position: absolute;
    height: fit-content;
    border-radius: 50px;
    background: none;
    z-index: 1;
  }

  @keyframes heartbeatAnimation {
    0%, 100% {
        transform: scale(1);
      }
      20%, 80% {
        transform: scale(1.05);
      }
      40%, 60% {
        transform: scale(1);
      }
  }
  
  .Tdicon {
    display: inline-block;
    animation: heartbeatAnimation 1s infinite;
    animation-delay: 5s; 
  }

  .hint{
    font-weight: 600;
    font-size: 20px;
    fontFamily: "Teko, sans-serif",
  }
  
  .bloom {
    position: absolute;
    box-shadow: 0px 0px 200px 90px rgba(255, 100, 0, 0.5);
    z-index: 0;
    top: 50%;
  }
  
  .modalPopBody{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0f0f0f
  }
  .ModalLogo{
    position: absolute;
    bottom: 0;
    opacity: 0.8;
  }

  .Modalbloom{
    position: absolute;
    box-shadow: 0px 0px 150px 150px rgba(255, 255, 255, 0.1);
    z-index: 0;
  }
  .ModalCloseBtn{
    position: absolute;
    top:10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 60px;
    width: fit-content;
    border-radius: 50px;
    right: 10px;
    z-index: 1;
    background: none;
    border: none;
    color:#fff;
    text-shadow:  4px 4px 100px rgba(255,0,0,0.6);;

  }

  .ModalCloseBtn:hover{
   background: none;
    color:red;
  }

  .ModalCloseBtn:active{
    background: none;
     color:red;

   }
/* Default styles for all screen sizes */
.rowS {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cols{
  height: 60vh;
}

@media (max-width: 575.98px) {
  .rowS {
      width: 100%;
  
  }
  .cols{
    height: 50vh;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .rowS{
      width: 100%;
  
  }
}

.CyborgBtn{
  padding: 10px;
  
  width: 100%;
  background: #CF0808;
  background: linear-gradient(to right, #CF0808 22%, #F5660D 100%);
  animation: heartbeatAnimation 2s initial;
  animation-delay: 5s;
  color: #fff;
  font-weight: 600;
  font-size: large;
  border-radius:2px ;
  text-decoration: none;
  text-align: center;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

}
.CyborgBtn:hover{
  box-shadow: 0px 0px 20px 1px rgba(255, 34, 34, 0.6);
}

.CyborgBtn:active{
  background: #cb550b;
  border: 1px solid #f5660d;

}