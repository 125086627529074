.spinner {
    width: 100px;
    height: 100px;
    animation: spinner-y0fdc1 2s infinite ease;
    transform-style: preserve-3d;
    z-index: 1;
    position:absolute;
 }
 
 .spinner > div {
    /* background-color: rgba(95, 255, 71, 0.2); */
    height: 100%;
    position: absolute;
    box-shadow:  0px 0px 100px 10px rgba(28,111,3,0.2);
    width: 100%;
    border: 0.1px solid #91ff4736;
 }
 
 .spinner div:nth-of-type(1) {
    transform: translateZ(-50px) rotateY(180deg);
 }
 
 .spinner div:nth-of-type(2) {
    transform: rotateY(-270deg) translateX(50%);
    transform-origin: top right;
 }
 
 .spinner div:nth-of-type(3) {
    transform: rotateY(270deg) translateX(-50%);
    transform-origin: center left;
 }
 
 .spinner div:nth-of-type(4) {
    transform: rotateX(90deg) translateY(-50%);
    transform-origin: top center;
 }
 
 .spinner div:nth-of-type(5) {
    transform: rotateX(-90deg) translateY(50%);
    transform-origin: bottom center;
 }
 
 .spinner div:nth-of-type(6) {
    transform: translateZ(50px);
 }
 
 @keyframes spinner-y0fdc1 {
    0% {
       transform: rotate(45deg) rotateX(-25deg) rotateY(25deg);
    }
 
    50% {
       transform: rotate(45deg) rotateX(-385deg) rotateY(25deg);
    }
 
    100% {
       transform: rotate(45deg) rotateX(-385deg) rotateY(385deg);
    }
 }