.technology-card-number {
    font-size: 3vw;
    color: #00ff40 !important;
    margin-bottom: 0 !important;
    font-family: "Roboto",sans-serif !important;
}

.technology-card-title {
    color: #ffffff;
    font-size: 2vw;
    font-weight: 600;
    text-align: start;
}

.technology-card-desc {
    margin-top: 1.5rem;
    color: #ffffff;
    font-size: 1.2vw;
    text-align: start;
    font-family: "Roboto",sans-serif !important;
}

.images{
    min-height: 100%;
}
/* Overlay animations */
.feature:hover .feature-overlay {
    display: flex;
}

.feature {
    position: relative;
    width: 25%;
}

.feature-title {
    --webkit-animation: fadein 500ms;
    animation: fadein 500ms;
}

.feature:hover .feature-title {
    display: none;
}

.feature-title {
    position: absolute;
    bottom: 5px;
    left: 1rem;
}

.feature-overlay .card {
   background: linear-gradient(to right top,#49c628 10%,#146814);
   border-radius: 0 !important;
}

.feature-overlay {
    position: absolute;
    top: 0;
    display: none;
    opacity: 0.8;
    --webkit-animation: fadein 700ms;
    animation: fadein 700ms;
}
.feature-card{
    display: none;
    position: absolute;
    --webkit-animation: popIn 1000ms;
    animation: popIn 1000ms;
}
.feature:hover .feature-card{
    display: block;
}

.read-more-btn{
    text-decoration: none;
    font-weight: bold;
}

/* Animations */

@keyframes fadein {
    from { opacity: 0; }
    to { opacity: 0.8; }
}

@-webkit-keyframes fadein {
    from { opacity: 0; }
    to { opacity: 0.8; }
}
@keyframes popIn {
    from { bottom: -5rem; }
    to { bottom: 0rem; }
}

/* Media Queries */

@media (max-width: 1024px) {
    .technology-card-number {
        font-size: 25px;
    }
    .technology-card-title{
        font-size: 20px;
      }
    .technology-card-desc{
        font-size: 12px;
    }
    .feature{
        width: 35%;
    }
}


@media (max-width: 992px){


    .feature{
        width: 50%;
    }
}

@media (max-width: 768px) {
    .technology-card-number {
        font-size: 25px;
    }
    .technology-card-title{
        font-size: 20px;
      }
    .technology-card-desc{
        font-size: 12px;
    }
    .feature{
        width: 50%;
    }
}

@media (max-width: 576px){
    .technology-card-number {
        font-size: 50px;
    }
    .technology-card-title{
        font-size: 23px;
      }
    .technology-card-desc{
        font-size: 15px;
    }
    .feature{
        width: 100%;
        
    }
}


