.footer-link{
    text-decoration: none;
    color: #fff;
    padding-bottom: 5px;

}

.footer-link:hover{
    color: greenyellow;
}

.footer-header{

    color: #545454;
    margin-bottom: 20px;
    margin-top: 2px;
}

.footer-icon{
    font-size: 26px;
    color: #fff;
}

.footer-icon:hover{
    color: greenyellow;
}